import React from "react"
import { Link } from "gatsby"
import FeatureColumn from "../components/feature-column"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faHandshake,
  faAward,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"

const WhyCelantur = () => {
  return (
    <section className="py-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center mb-8">
            <h2 className="font-weight-bold">Why Celantur?</h2>
          </div>
        </div>
        <div className="row">
          <FeatureColumn headline="Trust" icon={faHandshake}>
            Global industry leaders put trust in our products and expertise.
          </FeatureColumn>
          <FeatureColumn headline="Excellence" icon={faAward}>
            We are solving anonymization challenges, so you can focus on your
            core business.
          </FeatureColumn>
          <FeatureColumn headline="Commitment" icon={faUsers}>
            Our team is on your side, helping you on your privacy journey.
          </FeatureColumn>
        </div>
        <div className="row justify-content-center">
          <Link
            to="/contact/"
            role="button"
            className="btn btn-lg btn-success my-5 event-contact"
          >
            Contact Us <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default WhyCelantur
