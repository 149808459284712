import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import DataProtectionMeasures from "../components/data-protection-measures"
import FeatureColumn from "../components/feature-column"
import FeatureObjectTypes from "../components/feature-object-types"
import Testimonials from "../components/testimonials"
import DeploymentOptions from "../components/deployment-options"
import Demo from "../components/demo"
import WhyCelantur from "../components/why-celantur"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faHandshake,
  faDatabase,
  faCogs,
  faBalanceScale,
  faLock,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons"

const ImageVideoAnnotation = () => (
  <Layout>
    <SEO
      title="Automated Blurring for Image Annotation | Celantur"
      description="High-quality and scalable image anonymization solution to automatically blur faces, bodies, license plates on images and videos"
    />

    <section
      className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-50 bg-cover text-center"
      style={{
        background: "url(/images/rgb-city-cars-bodies.png) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <h1
          className="display-2 font-weight-bold text-white"
          style={{ textShadow: "2px 2px 4px #000000" }}
        >
          Automated <span className="text-success">Blurring</span>
          <br />
          for Image Annotation
        </h1>
        <p
          className="lead mb-6 mb-md-8 text-gray-200"
          style={{ textShadow: "2px 2px 4px #000000" }}
        >
          High-quality and scalable image anonymization solution.
          <br /> Automatically blur faces, bodies, license plates on images and
          videos.
        </p>
        <a
          href="https://app.celantur.com/"
          className="btn btn-success lift event-start-demo"
        >
          Start Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
        </a>
        <Link
          to="/contact/"
          className="btn btn-outline-light ml-3 lift event-contact"
        >
          Contact Us
        </Link>
      </div>
    </section>

    <section className="pt-5 pb-8">
      <Customers />
    </section>

    <section className="pt-5 pb-5">
      <div className="container">
        <FeatureObjectTypes />
      </div>
    </section>

    <section className="pt-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 mb-5 text-center">
            <h2 className="font-weight-bold">
              Image Annotation Privacy Challenges{" "}
              <span className="text-primary">easily solved</span>
            </h2>
            <p className="font-size-lg text-muted mb-6">
              By using Celantur, you can fulfill your customer's data protection
              demands while saving time and money.
            </p>
          </div>
        </div>
        <div className="row">
          <FeatureColumn headline="Quality" icon={faHandshake}>
            Industry-grade detection rate, trusted by global market leaders.
          </FeatureColumn>
          <FeatureColumn headline="Scalable and Fast" icon={faDatabase}>
            Handle massive datasets with ease.
          </FeatureColumn>
          <FeatureColumn headline="Easy Integration" icon={faCogs}>
            Many options to integrate Celantur into your data processing
            workflows.
          </FeatureColumn>
        </div>
        <div className="row">
          <FeatureColumn headline="GDPR-compliance" icon={faBalanceScale}>
            Comply with data protection laws around the globe, like the GDPR,
            CCPA, etc.
          </FeatureColumn>
          <FeatureColumn headline="Safe Data Sharing" icon={faLock}>
            Transfer data safely to third-parties or off-shore annotators.
          </FeatureColumn>
          <FeatureColumn headline="Save Costs" icon={faEuroSign}>
            Fully automated anonymization, no manual labor involved.
          </FeatureColumn>
        </div>
      </div>
    </section>

    <div
      className="alert alert-warning container text-center my-5"
      role="alert"
    >
      <span role="img" aria-label="celebration">
        🎉
      </span>{" "}
      Celantur has been positioned at MLReef's{" "}
      <strong>
        <a
          href="https://about.mlreef.com/blog/global-mlops-and-ml-tools-landscape"
          target="_blank"
          rel="noreferrer"
        >
          Global MLOps Platforms and ML Tools Landscape 2021
        </a>
      </strong>
      !
    </div>

    <section className="py-5 py-md-8">
      <div className="container">
      <DeploymentOptions />
      </div>
    </section>

    <Testimonials all={true} />

    <Demo />

    <DataProtectionMeasures />

    <WhyCelantur />

    <section className="py-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-md-8 text-center">
            <h2 className="font-weight-bold">Frequently Asked Questions</h2>
            <p className="font-size-lg text-muted mb-7 mb-md-9">
              Let us help answer the most common questions you might have
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h3>How long does anonymization usually take?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              Processing time may vary depending on several factors (resolution,
              total amount of objects to anonymize, hardware limitation, etc).
              For example, for 32 MP panorama images, we can anonymize 200.000
              images (cloud) and 20.000 (on-premise) in 24 hours.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>How can I use it?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              You can use our Cloud Service, where all the processing is done on
              our infrastructure. Or use our Docker container to deploy it
              on-premise or your private/public cloud environment.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>When to use Cloud Service or the Container?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              It depends on your use case, amount of data, frequency of usage
              and hardware availability. We are happy to help with finding the
              perfect fit for you.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>How much does your service cost?</h3>
            <p className="text-gray-800 mb-6 mb-md-8">
              We charge a fee per image or video hour. Here you can create a
              demo account and test it for free. For large projects, our Sales
              Team will assist you with a tailor-made offer based on your
              specific needs.
            </p>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default ImageVideoAnnotation
